import { oneticketApiCall } from "../utils/api/oneticketApi";



export const oneticketValidateAPI = async (pay_load:any) => {
    try {
      const data = await oneticketApiCall('user/event/transaction-session/validate/','POST',pay_load);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }
