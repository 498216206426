import { AxiosInstance, Method, AxiosRequestConfig, AxiosResponse } from "axios";
import EncriptionService from "../encryption.util";

export const apiCall = async (
  instance: AxiosInstance,
  url: string,
  method: Method = 'GET',
  data: any = null,
  header: string | null = null,
  encrypt: boolean = false,
  decrypt:boolean = true
): Promise<any> => {
  const { responseDecrypt, requestEncrypt } = EncriptionService();
  console.log(method)
  if (data && encrypt) {
    data = requestEncrypt(data);
  }

  const headers: AxiosRequestConfig['headers'] = {
    'Content-Type': 'application/json',
    ...(header && { Authorization: header }),
  };

  try {
    const response: AxiosResponse<any> = await instance.request({
      url,
      method,
      data,
      headers,
    });
    return decrypt? responseDecrypt(response.data) : response.data
  } catch (error) {
    // Log error or handle accordingly
    console.error("API call error: ", error);
    throw error;
  }
};
